#results-intro {
  padding-top: 100px;
  padding-bottom: 0px;
}

.step-iocn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto 20px;
  color: #fff;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-count {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #333;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  top: -5px;
  left: -5px;
  font-size: 14px;
  line-height: 30px;
}
.refine-link{
    color: $basecolor;
    border-bottom: 1px dashed #999;
    display: inline;
    /* text-decoration: underline; */
    cursor: pointer;
  }
#refine-search{
  display: none;

}


#search-results{
  padding-top:20px;
  padding-bottom:60px;
  .search-results-wrapper{
    padding-top:20px;
    -webkit-box-shadow: inset 0px 0px 10px -4px rgba(0,0,0,0.5);
    -moz-box-shadow: inset 0px 0px 10px -4px rgba(0,0,0,0.5);
    box-shadow: inset 0px 0px 10px -4px rgba(0,0,0,0.5);
    margin-bottom:20px;
    border-radius: 4px;
  }
    .venue-info{
      font-size: 22px;
      margin-bottom: 10px;
    }
  .result{
    background: #f7f7f7;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 700;
    .date{
      text-align: left;
      span{
        font-size: 22px;
        display: block;  
      }
    }
    .cost{
      text-align: center;
    }
    .sessions{
      text-align: center;
      span{
        font-size: 22px;
        display: block;  
      }
    }
    @media screen and (max-width:767px) {
      .cost{
          text-align: left;
        }
        .sessions{
          text-align: left;
          span{
            font-size: 22px;
            display: block;  
          }
        }

    }

  }
  
}

@media screen and (max-width:767px) {
  #results-intro {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}


