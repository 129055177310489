.footer-top {
  background: #2b2b2b;
  padding-top: 60px;
  padding-bottom: 60px;
  color: #bbb;
  font-size: 13px;
  line-height: 26px;
}

.footer-bottom {
  background: #222;
  padding-left: 15px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 20px;
      position: fixed;
    width: 100%;
    bottom: 0;
}

.footer-bottom i.fa {
  color: $basecolor;
}

.footer-widget > h4 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer-widget ul {
  padding: 0;
  list-style: none;
}
.tweet ul li {
    position: relative;
    padding-left: 20px;
}
.tweet ul li:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    font-family: fontawesome;
    color: #33ccff;
    font-size: 15px;
}
.widget-instargram ul > li {
  width: 33.33333%;
  float: left;
  padding: 3px;
}

#instafeed > a {
  display: block;
  width: 33.33%;
  float: left;
  padding: 3px;
}
#instafeed img {
  width: 100%;
}
@media screen and (max-width:991px) {
  .footer-widget {
    margin-bottom: 30px;
  }

  br {
    display: none !important;
  }
}












