#features {
  padding-top: 120px;
  padding-bottom: 120px;
}

.feature-slider .owl-nav > div {
  width: 50px;
  height: 50px;
  background: #eee;
  color: #666;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: .3s;
}

.feature-slider .owl-nav > div:hover {
  background: $basecolor;
  color: #fff;
}

.feature-slider .owl-nav {
  position: absolute;
  top: 25%;
  width: 100%;
}

.feature-slider .owl-prev {
  float: left;
  margin-left: -60px;
}

.feature-slider .owl-next {
  float: right;
  margin-right: -60px;
}

@media screen and (max-width:1200px) {
  .feature-slider .owl-prev {
    margin-left: 0;
  }

  .feature-slider .owl-next {
    margin-right: 0;
  }
}

@media screen and (max-width:767px) {
  #features {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
