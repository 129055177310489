#parties {
  background: url(../img/parties-bg.jpg) no-repeat scroll 0 0;
  background-size: cover;
  background-position: top;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-top: 80px;
      min-height: 100vh;
}
#parties-text{
  background:white;
  -webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px;
padding:20px;
margin-bottom: 40px;
}

.approch-text .color-text {
  font-weight: 600;
}

@media screen and (max-width:991px) {
  #parties {
    padding-bottom: 120px;
  }

  .approach-thumb {
    margin-bottom: 50px;
  }
}

@media screen and (max-width:767px) {
  #parties {
    padding-top: 70;
    padding-bottom: 70px;
  }
}
