#main-benefits {
  padding-top: 100px;
  padding-bottom: 120px;
}

.benefit-count {
  height: 70px;
  overflow: hidden;
}

.benefit-count > span {
  font-size: 100px;
  vertical-align: bottom;
  line-height: 1.07;
  font-weight: 600;
  color: #eeeeee;
}

@media screen and (max-width:767px) {
  #main-benefits {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}