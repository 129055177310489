
.registration-form, .search-form {
    background: #fff;
    padding: 35px;
    border-radius: 4px;
}

.registration-form  .form-control, .search-form  .form-control {
    background: #f6f6f6;
    border: 1px solid transparent;
    box-shadow: none;
    padding: 13px;
    height: 50px;
}
.registration-form .has-error .form-control, .search-form .has-error .form-control {
  border-color:red;
}
.has-error .form-control:focus {
  box-shadow: none;
}
.registration-form .form-group, .search-form .form-group{
  margin-bottom: 0;
}
@media screen and (max-width:991px){
  .registration-form .form-group,  .search-form .form-group{
    margin-bottom: 15px;
  }

}
