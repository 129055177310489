#steps {
  padding-top: 100px;
  padding-bottom: 120px;
}

.step-iocn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto 20px;
  color: #fff;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-count {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #333;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  top: -5px;
  left: -5px;
  font-size: 14px;
  line-height: 30px;
}

@media screen and (max-width:767px) {
  #steps {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}


