#faq {
  //background: url(../img/fag-bg.jpg) no-repeat scroll 0 0;
  //background-size: cover;
  padding-top: 100px;
  padding-bottom: 120px;
}

#faq .section-title {
  font-size: 24px;
}

.faq-block {
  margin-top: 30px;
}

.faq-block > h4 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 28px;
}

@media screen and (max-width:767px) {
  #faq {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}