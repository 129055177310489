#class-intro {
  padding-top: 100px;
  padding-bottom: 0px;
}



#class-info{
  padding-top:20px;
  padding-bottom:60px;
  .search-results-wrapper{
    padding-top:20px;
    -webkit-box-shadow: inset 0px 0px 10px -4px rgba(0,0,0,0.5);
    -moz-box-shadow: inset 0px 0px 10px -4px rgba(0,0,0,0.5);
    box-shadow: inset 0px 0px 10px -4px rgba(0,0,0,0.5);
    margin-bottom:20px;
    border-radius: 4px;
    overflow:auto;
  }
    .venue-info{
      font-size: 22px;
      margin-bottom: 10px;
    }
  .result{
    background: #f7f7f7;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 700;
    overflow:auto;
    .date{
      text-align: left;
      span{
        font-size: 22px;
        display: block;  
      }
    }
    .age{
      text-align: left;
      span{
        font-size: 22px;
        display: block;  
      }
    }
    .kit{
      text-align: left;
      span{
        font-size: 22px;
        display: block;  
      }
    }
    .cost{
      text-align: left;
      span{
        font-size: 22px;
        display: block;  
      }
    }
    .sessions{
      text-align: left;
      span{
        font-size: 22px;
        display: block;  
      }
    }
    @media screen and (max-width:767px) {
      .cost{
          text-align: left;
        }
        .sessions{
          text-align: left;
          span{
            font-size: 22px;
            display: block;  
          }
        }

    }
  }
  
}
#strikerForm, #waitingListForm{
  padding-top: 20px;
    box-shadow: inset 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    border-radius: 4px;
    display: none;
}

#payment-loader{
    background: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 999;
    top:0;
    display: none;
    .loader-icon{
      height: 50px;
      left: 50%;
      margin: -20px 0 0 -20px;
      position: absolute;
      text-align: center;
      top: 40%;
      width: 50px;
    }
  h3{
    height: 50px;
    left: 50%;
    margin: 60px 0 0 -200px;
    position: absolute;
    text-align: center;
    top: 40%;
    width: 400px;
  }
}
#mapWrapper{
    margin-bottom:40px;
    .address{
      padding:10px;
      background: #f7f7f7;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 700;
      a{
        color:#337ab7;
      }
    }
}

.contact-details{
  a{
    color:#337ab7;
  }
  ul{
    padding-left:0;
    li{
      list-style: none;
    }
  }
}



@media screen and (max-width:767px) {
  #class-intro {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}


