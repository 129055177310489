
#main-nav {
    padding-top: 15px;
    padding-bottom: 15px;
    transition: .3s;
}

.nav.navbar-nav > li > a {
    font-weight: 500;
    text-transform: capitalize;
    display: inline-block;
    padding: 10px 15px;
    line-height: normal;
}
.nav.navbar-nav > li > a.btn-call-to-action {
  background: $basecolor;
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
}
.nav.navbar-nav > li > a:hover,
.nav.navbar-nav > li > a:active,
.nav.navbar-nav > li > a:focus {
    background: inherit;
    color: $basecolor;
}
.nav.navbar-nav > li > a.btn-call-to-action:hover,
.nav.navbar-nav > li > a.btn-call-to-action:active,
.nav.navbar-nav > li > a.btn-call-to-action:focus {
    background: $basecolor;
    color: #fff;
}
.is-scrolling {
    background: #fff;
    color: #000;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.navbar-brand {
    font-size: 20px;
    font-weight: bold;
    position: relative;
    height: auto;
  max-height: 50px;
      padding: 0;
}
.logo-dark {
    position: absolute;
    top: 0;
    //padding: 15px;
    left: 0;
    opacity: 0;
    transition: .3s;
}
.navbar-fixed-top.is-scrolling .logo-default {
    opacity: 0;
}
.navbar-fixed-top.is-scrolling .logo-dark {
    opacity: 1;
}
@media screen and (max-width: 991px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {        
        display: block !important;
    }
    .nav.navbar-nav.pull-right {
        float: none !important;
        padding-left: 15px;
    }
    .nav.navbar-nav > li {
        float: none;
    }
    .navbar-toggle {
        background: $basecolor;
    }
    .navbar-toggle .icon-bar {
        background: #fff;
    } 
.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
    max-height: 500px;
}
    #main-nav {
        background: #fff;
        color: #000;
    }
    .logo-default {
        opacity: 0;
    }
    .logo-dark {
        opacity: 1;
    }
}