@charset "UTF-8";
/************
  Theme Name: Beborn
   Theme URI: http://echotheme.com/arwen
      Author: Echo Theme
  Author URI: http://echotheme.com
     Version: 1.0.0
        Tags: Beborn
 Text Domain: Beborn
 Description: Our Beborn theme is clean designed for clarity. Beborn simple, 
              straightforward typography is readable on a  wide variety of screen sizes,
              and suitable for multiple languages. We designed it using a mobile-first approach,
              meaning your content takes center-stage, regardless of whether your visitors arrive
              by smartphone, tablet, laptop, or desktop computer.

\************/
/**
   ***** TABLE OF CONTENTS

01.global
02.Global...............Globally-available variables and config.
03.intro
04.nav
05.form
06.steps
07.approach
08.features
09.fun-fact
10.coverage
11.testimonial
12.main-benefits
13.faq
14.pricing
15.contact
16.footer
 */
/*------------------------------------*#VARIABLE AND MIXINS
\*------------------------------------*/
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 30px;
  color: #666;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  font-size: inherit;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

a:hover, a:focus, a:active {
  text-decoration: none;
  color: #0088c4;
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: #000;
}

h1, h2 {
  font-size: 48px;
}

.no-padding {
  padding: 0;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-left {
  padding-left: 0;
}

.no-margin {
  margin: 0;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.section-title {
  font-size: 48px;
  font-weight: 900;
  color: #000000;
}

.section-tagline {
  margin-bottom: 30px;
}

.color-text {
  color: #0088c4;
}

.bg-yellow {
  background: #ffb81e;
}

.bg-theme {
  background: #0088c4;
}

.bg-light-green {
  background: #76de51;
}

.bg-light-red {
  background: #f75e61;
}

.block-title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-top: 25px;
}

.btn.btn-theme {
  background: #0088c4;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  min-width: 170px;
  height: 50px;
  padding: 15px;
  color: #fff;
  line-height: normal;
}

#page-loader {
  background: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
}

.loader-icon {
  background: none repeat scroll 0 0 #fff;
  border-bottom: 3px solid rgba(19, 19, 19, 0.1) !important;
  border-left: 3px solid rgba(19, 19, 19, 0.1) !important;
  border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -o-border-radius: 100px;
  -ms-border-radius: 100px;
  border-right: 3px solid #0088c4 !important;
  border-top: 3px solid rgba(19, 19, 19, 0.1) !important;
  height: 50px;
  left: 50%;
  margin: -20px 0 0 -20px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 50px;
}

.colored-border {
  border-color: #fff;
}

.fa-spin {
  -webkit-animation: fa-spin 1s infinite linear;
  animation: fa-spin 1s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

hr {
  height: 10px;
  border: 0;
  box-shadow: 0 10px 10px -10px #8c8b8b inset;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .section-title {
    font-size: 30px;
  }
}

.scroll-top {
  background: #0088c4;
  bottom: 20px;
  color: #fff;
  font-size: 20px;
  height: 40px;
  position: fixed;
  text-align: center;
  width: 40px;
  z-index: 10;
  cursor: pointer;
  transition: .3s;
  border-radius: 50%;
  line-height: 40px;
  right: -100px;
}

.scroll-top.active {
  right: 20px;
}

label.error {
  font-weight: 400;
  color: red;
}

.text-orange {
  color: #ffb81e !important;
}

.btn-orange {
  background: #ffb81e !important;
}

.grey-box {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
}

.pbs {
  display: inline-block;
  margin: 0 10px;
}

#intro-hero {
  background: url(/img/little-strikers-bg.jpg) no-repeat scroll 0 0;
  background-size: cover;
  background-position: top;
  padding-bottom: 50px;
  position: relative;
  z-index: 10;
  color: #fff;
  min-height: 90vh;
}

#intro-hero:after {
  content: '';
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

#hero-content {
  color: #fff;
  padding-top: 120px;
  padding-bottom: 100px;
}

.hero-title {
  font-size: 48px;
  font-weight: 900;
  color: #fff;
}

.hero-tagline {
  font-size: 16px;
}

.play-video {
  font-size: 14px;
  font-weight: 600;
}

.play-video .icon.icon-play-button2 {
  vertical-align: middle;
  margin-left: 5px;
  font-size: 30px;
}

.intro-btn > a {
  margin: 0 10px;
}

@media screen and (max-width: 767px) {
  #hero-content {
    padding-top: 120px;
    padding-bottom: 50px;
  }
  .hero-title {
    font-size: 34px;
    line-height: 40px;
  }
  .intro-btn > a {
    margin: 20px 10px;
  }
  .no-padding {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#main-nav {
  padding-top: 15px;
  padding-bottom: 15px;
  transition: .3s;
}

.nav.navbar-nav > li > a {
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  padding: 10px 15px;
  line-height: normal;
}

.nav.navbar-nav > li > a.btn-call-to-action {
  background: #0088c4;
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
}

.nav.navbar-nav > li > a:hover,
.nav.navbar-nav > li > a:active,
.nav.navbar-nav > li > a:focus {
  background: inherit;
  color: #0088c4;
}

.nav.navbar-nav > li > a.btn-call-to-action:hover,
.nav.navbar-nav > li > a.btn-call-to-action:active,
.nav.navbar-nav > li > a.btn-call-to-action:focus {
  background: #0088c4;
  color: #fff;
}

.is-scrolling {
  background: #fff;
  color: #000;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.navbar-brand {
  font-size: 20px;
  font-weight: bold;
  position: relative;
  height: auto;
  max-height: 50px;
  padding: 0;
}

.logo-dark {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: .3s;
}

.navbar-fixed-top.is-scrolling .logo-default {
  opacity: 0;
}

.navbar-fixed-top.is-scrolling .logo-dark {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .nav.navbar-nav.pull-right {
    float: none !important;
    padding-left: 15px;
  }
  .nav.navbar-nav > li {
    float: none;
  }
  .navbar-toggle {
    background: #0088c4;
  }
  .navbar-toggle .icon-bar {
    background: #fff;
  }
  .navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
    max-height: 500px;
  }
  #main-nav {
    background: #fff;
    color: #000;
  }
  .logo-default {
    opacity: 0;
  }
  .logo-dark {
    opacity: 1;
  }
}

.registration-form, .search-form {
  background: #fff;
  padding: 35px;
  border-radius: 4px;
}

.registration-form .form-control, .search-form .form-control {
  background: #f6f6f6;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 13px;
  height: 50px;
}

.registration-form .has-error .form-control, .search-form .has-error .form-control {
  border-color: red;
}

.has-error .form-control:focus {
  box-shadow: none;
}

.registration-form .form-group, .search-form .form-group {
  margin-bottom: 0;
}

@media screen and (max-width: 991px) {
  .registration-form .form-group, .search-form .form-group {
    margin-bottom: 15px;
  }
}

#steps {
  padding-top: 100px;
  padding-bottom: 120px;
}

.step-iocn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto 20px;
  color: #fff;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-count {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #333;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  top: -5px;
  left: -5px;
  font-size: 14px;
  line-height: 30px;
}

@media screen and (max-width: 767px) {
  #steps {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

#results-intro {
  padding-top: 100px;
  padding-bottom: 0px;
}

.step-iocn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto 20px;
  color: #fff;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-count {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #333;
  border-radius: 50%;
  color: #fff;
  font-weight: 600;
  top: -5px;
  left: -5px;
  font-size: 14px;
  line-height: 30px;
}

.refine-link {
  color: #0088c4;
  border-bottom: 1px dashed #999;
  display: inline;
  /* text-decoration: underline; */
  cursor: pointer;
}

#refine-search {
  display: none;
}

#search-results {
  padding-top: 20px;
  padding-bottom: 60px;
}

#search-results .search-results-wrapper {
  padding-top: 20px;
  -webkit-box-shadow: inset 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  border-radius: 4px;
}

#search-results .venue-info {
  font-size: 22px;
  margin-bottom: 10px;
}

#search-results .result {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
}

#search-results .result .date {
  text-align: left;
}

#search-results .result .date span {
  font-size: 22px;
  display: block;
}

#search-results .result .cost {
  text-align: center;
}

#search-results .result .sessions {
  text-align: center;
}

#search-results .result .sessions span {
  font-size: 22px;
  display: block;
}

@media screen and (max-width: 767px) {
  #search-results .result .cost {
    text-align: left;
  }
  #search-results .result .sessions {
    text-align: left;
  }
  #search-results .result .sessions span {
    font-size: 22px;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  #results-intro {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}

#class-intro {
  padding-top: 100px;
  padding-bottom: 0px;
}

#class-info {
  padding-top: 20px;
  padding-bottom: 60px;
}

#class-info .search-results-wrapper {
  padding-top: 20px;
  -webkit-box-shadow: inset 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: auto;
}

#class-info .venue-info {
  font-size: 22px;
  margin-bottom: 10px;
}

#class-info .result {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 700;
  overflow: auto;
}

#class-info .result .date {
  text-align: left;
}

#class-info .result .date span {
  font-size: 22px;
  display: block;
}

#class-info .result .age {
  text-align: left;
}

#class-info .result .age span {
  font-size: 22px;
  display: block;
}

#class-info .result .kit {
  text-align: left;
}

#class-info .result .kit span {
  font-size: 22px;
  display: block;
}

#class-info .result .cost {
  text-align: left;
}

#class-info .result .cost span {
  font-size: 22px;
  display: block;
}

#class-info .result .sessions {
  text-align: left;
}

#class-info .result .sessions span {
  font-size: 22px;
  display: block;
}

@media screen and (max-width: 767px) {
  #class-info .result .cost {
    text-align: left;
  }
  #class-info .result .sessions {
    text-align: left;
  }
  #class-info .result .sessions span {
    font-size: 22px;
    display: block;
  }
}

#strikerForm, #waitingListForm {
  padding-top: 20px;
  box-shadow: inset 0px 0px 10px -4px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  border-radius: 4px;
  display: none;
}

#payment-loader {
  background: #fff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  display: none;
}

#payment-loader .loader-icon {
  height: 50px;
  left: 50%;
  margin: -20px 0 0 -20px;
  position: absolute;
  text-align: center;
  top: 40%;
  width: 50px;
}

#payment-loader h3 {
  height: 50px;
  left: 50%;
  margin: 60px 0 0 -200px;
  position: absolute;
  text-align: center;
  top: 40%;
  width: 400px;
}

#mapWrapper {
  margin-bottom: 40px;
}

#mapWrapper .address {
  padding: 10px;
  background: #f7f7f7;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
}

#mapWrapper .address a {
  color: #337ab7;
}

.contact-details a {
  color: #337ab7;
}

.contact-details ul {
  padding-left: 0;
}

.contact-details ul li {
  list-style: none;
}

@media screen and (max-width: 767px) {
  #class-intro {
    padding-top: 70px;
    padding-bottom: 0px;
  }
}

#approach {
  background: url(../img/payment-bg.jpg) no-repeat scroll 0 0;
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-top: 80px;
}

#approach.failed {
  background: url(../img/payment-bg-fail.jpg) no-repeat scroll 0 0;
  background-size: cover;
}

.approch-text .color-text {
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  #approach {
    padding-bottom: 120px;
  }
  .approach-thumb {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  #approach {
    padding-top: 70;
    padding-bottom: 70px;
  }
}

#about {
  background: url(../img/about-bg.jpg) no-repeat scroll 0 0;
  background-size: cover;
  background-position: top;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-top: 80px;
  min-height: 100vh;
}

#about-text {
  background: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}

.approch-text .color-text {
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  #about {
    padding-bottom: 120px;
  }
  .approach-thumb {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  #about {
    padding-top: 70;
    padding-bottom: 70px;
  }
}

#parties {
  background: url(../img/parties-bg.jpg) no-repeat scroll 0 0;
  background-size: cover;
  background-position: top;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-top: 80px;
  min-height: 100vh;
}

#parties-text {
  background: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}

.approch-text .color-text {
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  #parties {
    padding-bottom: 120px;
  }
  .approach-thumb {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  #parties {
    padding-top: 70;
    padding-bottom: 70px;
  }
}

#nurseries {
  background: url(../img/nurseries-bg.jpg) no-repeat scroll 0 0;
  background-size: cover;
  background-position: top;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-top: 80px;
  min-height: 100vh;
}

#nurseries-text {
  background: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}

.approch-text .color-text {
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  #nurseries {
    padding-bottom: 120px;
  }
  .approach-thumb {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  #nurseries {
    padding-top: 70;
    padding-bottom: 70px;
  }
}

#features {
  padding-top: 120px;
  padding-bottom: 120px;
}

.feature-slider .owl-nav > div {
  width: 50px;
  height: 50px;
  background: #eee;
  color: #666;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: .3s;
}

.feature-slider .owl-nav > div:hover {
  background: #0088c4;
  color: #fff;
}

.feature-slider .owl-nav {
  position: absolute;
  top: 25%;
  width: 100%;
}

.feature-slider .owl-prev {
  float: left;
  margin-left: -60px;
}

.feature-slider .owl-next {
  float: right;
  margin-right: -60px;
}

@media screen and (max-width: 1200px) {
  .feature-slider .owl-prev {
    margin-left: 0;
  }
  .feature-slider .owl-next {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  #features {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

#fun-facts {
  background: url(../img/fun-fact-bg.jpg) no-repeat fixed;
  background-position: center center;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  color: #fff;
  z-index: 1;
}

#fun-facts:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #0088c4;
  background: rgba(0, 136, 196, 0.9);
  z-index: -1;
}

.fun-fact-block > h2 {
  color: #fff;
}

.fact-icon {
  font-size: 18px;
}

@media screen and (max-width: 991px) {
  .fun-fact-block {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  #fun-facts {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

#media-coverage {
  padding-top: 100px;
  padding-bottom: 200px;
}

.list-inline.logos > li {
  width: 20%;
  float: left;
  display: flex;
  height: 90px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #media-coverage {
    padding-top: 70px;
    padding-bottom: 150px;
  }
  .list-inline.logos > li {
    width: 33.33337%;
    height: 100px;
  }
}

#testimonials {
  background: #f6f6f6;
  padding-bottom: 160px;
  padding-top: 120px;
}

.testimonials-box > h4 {
  color: #ffb81e;
  font-size: 24px;
  line-height: 35px;
  font-weight: 600;
}

.testimonials-box {
  background: #3e3e3e;
  color: #fff;
  padding: 30px;
  border-radius: 4px;
  position: absolute;
  min-height: 530px;
  top: -200px;
}

.testimonial-slides:before {
  content: '';
  position: absolute;
  top: 0;
  left: 100px;
  font-family: "icomoon";
  font-size: 150px;
  color: #ececec;
  top: -110px;
}

.tm-item {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}

.tm-author-avatar {
  width: 80px;
  height: 80px;
  float: left;
  border-radius: 50%;
  overflow: hidden;
}

.tm-text {
  margin-left: 100px;
}

.tm-text > h4 {
  color: #000000;
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.tm-text > span {
  color: #bbb;
}

.owl-dots {
  text-align: center;
  margin-top: 30px;
}

.owl-dot {
  width: 10px;
  height: 10px;
  background: #e3e3e3;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  margin: 4px;
}

.owl-dot.active {
  background: #0088c4;
}

@media screen and (max-width: 991px) {
  .testimonials-box {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    min-height: auto;
    top: -200px;
  }
}

@media screen and (max-width: 991px) {
  #testimonials {
    padding-bottom: 70px;
  }
}

#main-benefits {
  padding-top: 100px;
  padding-bottom: 120px;
}

.benefit-count {
  height: 70px;
  overflow: hidden;
}

.benefit-count > span {
  font-size: 100px;
  vertical-align: bottom;
  line-height: 1.07;
  font-weight: 600;
  color: #eeeeee;
}

@media screen and (max-width: 767px) {
  #main-benefits {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

#faq {
  padding-top: 100px;
  padding-bottom: 120px;
}

#faq .section-title {
  font-size: 24px;
}

.faq-block {
  margin-top: 30px;
}

.faq-block > h4 {
  color: #000;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 28px;
}

@media screen and (max-width: 767px) {
  #faq {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

#pricing {
  padding-top: 90px;
  padding-bottom: 120px;
}

.pricing-plan {
  border-top: 1px solid #eee;
  min-height: 560px;
  margin-top: 50px;
  transition: .5s;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
}

.plan-head {
  padding: 15px 0 5px;
  border-bottom: 1px solid #eee;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
}

.plan-value {
  border-bottom: 1px solid #eee;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.plan-value > h4 {
  font-size: 48px;
  color: #000000;
  font-weight: bold;
  transition: .5s;
}

.plan-value > h4 > sup {
  font-weight: normal;
  font-size: 14px;
  top: -30px;
}

.plan-feature ul {
  padding: 0;
  margin: 0;
}

.plan-feature li {
  padding: 10px;
  list-style: none;
  border-bottom: 1px solid #eee;
}

.plan-feature li .text-muted {
  color: #bbbbbb;
}

.plan-buy > .btn.btn-theme {
  background: #222;
  border-radius: 50px;
  margin: 30px;
  font-size: 12px;
  letter-spacing: 2px;
}

#pricing .col-md-3.col-sm-6:last-child .pricing-plan {
  border-right: 1px solid #eee;
}

.pricing-plan.recommanded {
  box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.1);
  transform: scaleY(1.01);
}

.pricing-plan:hover .plan-value > h4,
.pricing-plan.recommanded .plan-value > h4 {
  color: #0088c4;
}

.pricing-plan:hover .plan-head,
.pricing-plan.recommanded .plan-head {
  color: #0088c4;
}

.pricing-plan:hover .btn.btn-theme,
.pricing-plan.recommanded .btn.btn-theme {
  background: #0088c4;
}

@media screen and (max-width: 991px) {
  .pricing-plan {
    margin-bottom: 30px;
    border: 1px solid #eee;
  }
}

@media screen and (max-width: 767px) {
  #pricing {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.team-top-title .section-title {
  color: #fff;
  font-size: 36px;
  margin-top: 10px;
}

.team-top-title {
  background: #333;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 30px;
}

.team-single {
  position: relative;
}

.team-img img {
  width: 100%;
}

.team-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  background: #0088c4;
  background: rgba(0, 136, 196, 0.8);
  justify-content: center;
  color: #fff;
  opacity: 0;
  transition: .5s;
}

.team-overlay > h4 {
  font-size: 24px;
  margin-bottom: 0;
  color: #fff;
}

.team-social > a {
  width: 30px;
  height: 30px;
  background: #0088c4;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
}

.team-social > a:hover {
  color: #0088c4;
  background: #fff;
}

.team-single:hover .team-overlay {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .team-single {
    margin-bottom: 30px;
  }
}

#contact {
  padding-top: 100px;
  padding-bottom: 120px;
}

#contact .block-title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  margin-top: 0;
}

.social > .list-inline > li > a > i {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: #fff;
  transform: .5s;
}

.social > .list-inline > li > a > i.fa-facebook {
  background: #365493;
}

.social > .list-inline > li > a > i.fa-twitter {
  background: #33ccff;
}

.social > .list-inline > li > a > i.fa-google-plus {
  background: #d44132;
}

.social > .list-inline > li > a > i.fa-pinterest-p {
  background: #cb2027;
}

.social > .list-inline > li > a > i.fa-instagram {
  background: -webkit-radial-gradient(32% 106%, circle cover, #ffe17d 0%, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), -webkit-linear-gradient(-45deg, #234bd7 12%, #c33cbe 58%);
  background: -moz-radial-gradient(32% 106%, circle cover, #ffe17d 0%, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), -moz-linear-gradient(-45deg, #234bd7 12%, #c33cbe 58%);
  background: -ms-radial-gradient(32% 106%, circle cover, #ffe17d 0%, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), -ms-linear-gradient(-45deg, #234bd7 12%, #c33cbe 58%);
  background: -o-radial-gradient(32% 106%, circle cover, #ffe17d 0%, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), -o-linear-gradient(-45deg, #234bd7 12%, #c33cbe 58%);
  background: radial-gradient(circle farthest-corner at 32% 106%, #ffe17d 0%, #ffcd69 10%, #fa9137 28%, #eb4141 42%, transparent 82%), linear-gradient(135deg, #234bd7 12%, #c33cbe 58%);
}

.social > .list-inline > li > a > i:hover {
  transform: scale(1.05);
}

#contact .form-control {
  border: 1px solid #eeeeee;
  box-shadow: none;
  padding: 13px;
  height: 44px;
  font-size: 13px;
}

.success-message {
  position: absolute;
  top: -50px;
  color: #0088c4;
  width: 100%;
  height: 30px;
  z-index: 1;
  font-weight: bold;
  opacity: 0;
  transition: .5s;
  left: 0;
}

#contact .has-error .form-control {
  border-color: red !important;
}

#contact textarea.form-control {
  height: auto;
}

@media screen and (max-width: 767px) {
  #contact {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .contact-form {
    margin-top: 50px;
  }
}

#licensing {
  background: url(../img/franchise-bg.jpg) no-repeat scroll 0 0;
  background-size: cover;
  background-position: top;
  padding-top: 70px;
  padding-bottom: 70px;
  margin-top: 80px;
  min-height: 100vh;
}

#licensing-text {
  background: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}

.approch-text .color-text {
  font-weight: 600;
}

@media screen and (max-width: 991px) {
  #licensing {
    padding-bottom: 120px;
  }
  .approach-thumb {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  #licensing {
    padding-top: 70;
    padding-bottom: 70px;
  }
}

.footer-top {
  background: #2b2b2b;
  padding-top: 60px;
  padding-bottom: 60px;
  color: #bbb;
  font-size: 13px;
  line-height: 26px;
}

.footer-bottom {
  background: #222;
  padding-left: 15px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 20px;
  position: fixed;
  width: 100%;
  bottom: 0;
}

.footer-bottom i.fa {
  color: #0088c4;
}

.footer-widget > h4 {
  color: #fff;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer-widget ul {
  padding: 0;
  list-style: none;
}

.tweet ul li {
  position: relative;
  padding-left: 20px;
}

.tweet ul li:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  font-family: fontawesome;
  color: #33ccff;
  font-size: 15px;
}

.widget-instargram ul > li {
  width: 33.33333%;
  float: left;
  padding: 3px;
}

#instafeed > a {
  display: block;
  width: 33.33%;
  float: left;
  padding: 3px;
}

#instafeed img {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .footer-widget {
    margin-bottom: 30px;
  }
  br {
    display: none !important;
  }
}
