#intro-hero {
  background: url(/img/little-strikers-bg.jpg) no-repeat scroll 0 0;
  background-size: cover;
  background-position: top;
  padding-bottom: 50px;
  position: relative;
  z-index: 10;
  color: #fff;
      min-height: 90vh
}
#intro-hero:after {
  content: '';
  background: rgba(0,0,0,.30);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
} 

#hero-content {
  color: #fff;
  padding-top: 120px;
  padding-bottom: 100px;
}

.hero-title {
  font-size: 48px;
  font-weight: 900;
  color: #fff;
}
.hero-tagline {
  font-size: 16px;
}
.play-video {
    font-size: 14px;
    font-weight: 600;
}
.play-video .icon.icon-play-button2 {
    vertical-align: middle;
    margin-left: 5px;
    font-size: 30px;
}
.intro-btn > a {
  margin: 0 10px;
}
@media screen and (max-width:767px){
  #hero-content {
    padding-top: 120px;
    padding-bottom: 50px;
}
  .hero-title {
    font-size: 34px;
  line-height: 40px;
  }
  
  .intro-btn > a {
    margin: 20px 10px;
}
  .no-padding {
    padding-left: 15px;
    padding-right: 15px;
  }

  
}










