#media-coverage {
  padding-top: 100px;
  padding-bottom: 200px;
}

.list-inline.logos > li {
  width: 20%;
  float: left;
  display: flex;
  height: 90px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@media screen and (max-width:767px) {
  #media-coverage {
    padding-top: 70px;
    padding-bottom: 150px;
  }

  .list-inline.logos>li {
    width: 33.33337%;
    height: 100px;
  }
}