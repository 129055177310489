#contact {
  padding-top: 100px;
  padding-bottom: 120px;
}

#contact .block-title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
  margin-top: 0;
}

.social > .list-inline > li > a > i {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: #fff;
  transform: .5s;
}

.social > .list-inline > li > a > i.fa-facebook {
  background: #365493;
}

.social > .list-inline > li > a > i.fa-twitter {
  background: #33ccff;
}
.social > .list-inline > li > a > i.fa-google-plus {
  background: #d44132;
}

.social > .list-inline > li > a > i.fa-pinterest-p {
  background: #cb2027;
}

.social > .list-inline > li > a > i.fa-instagram {
  background: -webkit-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -webkit-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
  background: -moz-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -moz-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
  background: -ms-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -ms-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
  background: -o-radial-gradient(32% 106%, circle cover, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), -o-linear-gradient(-45deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
  background: radial-gradient(circle farthest-corner at 32% 106%, rgb(255, 225, 125) 0%, rgb(255, 205, 105) 10%, rgb(250, 145, 55) 28%, rgb(235, 65, 65) 42%, transparent 82%), linear-gradient(135deg, rgb(35, 75, 215) 12%, rgb(195, 60, 190) 58%);
}



.social > .list-inline > li > a > i:hover {
  transform: scale(1.05);
}

#contact .form-control {
  border: 1px solid #eeeeee;
  box-shadow: none;
  padding: 13px;
  height: 44px;
  font-size: 13px;
}
.success-message {
  position: absolute;
  top: -50px;
  color: $basecolor;
  width: 100%;
  height: 30px;
  z-index: 1;
  font-weight: bold;
  opacity: 0;
  transition: .5s;
  left: 0;
}

#contact .has-error .form-control {
  border-color: red !important;
}
#contact textarea.form-control {
  height: auto;
}

@media screen and (max-width:767px) {
  #contact {
    padding-top: 70px;
    padding-bottom: 70px;
  }
 .contact-form {
    margin-top: 50px;
} 
  
  
}
