#testimonials {
  background: #f6f6f6;
  padding-bottom: 160px;
  padding-top: 120px;
}

.testimonials-box > h4 {
  color: #ffb81e;
  font-size: 24px;
  line-height: 35px;
  font-weight: 600;
}

.testimonials-box {
  background: #3e3e3e;
  color: #fff;
  padding: 30px;
  border-radius: 4px;
  position: absolute;
  min-height: 530px;
  top: -200px;
}

.testimonial-slides:before {
  content: '';
  position: absolute;
  top: 0;
  left: 100px;
  font-family: "icomoon";
  font-size: 150px;
  color: #ececec;
  top: -110px;
}

.tm-item {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
}

.tm-author-avatar {
  width: 80px;
  height: 80px;
  float: left;
  border-radius: 50%;
  overflow: hidden;
}

.tm-text {
  margin-left: 100px;
}

.tm-text > h4 {
  color: #000000;
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.tm-text > span {
  color: #bbb;
}

.owl-dots {
  text-align: center;
  margin-top: 30px;
}

.owl-dot {
  width: 10px;
  height: 10px;
  background: #e3e3e3;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  margin: 4px;
}

.owl-dot.active {
  background: $basecolor;
}

@media screen and (max-width:991px) {
  .testimonials-box {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    min-height: auto;
    top: -200px;
  }
}

@media screen and (max-width:991px) {
  #testimonials {
    padding-bottom: 70px;
  }
}

